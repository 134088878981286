import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row gy-5 gx-xl-8" }
const _hoisted_2 = {
  key: 0,
  class: "col-xxl-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptionsWidget = _resolveComponent("OptionsWidget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.Options != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_OptionsWidget, {
            Options: _ctx.Options,
            "widget-classes": "card-xxl-stretch mb-5 mb-xl-8"
          }, null, 8, ["Options"])
        ]))
      : _createCommentVNode("", true)
  ]))
}