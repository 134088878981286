
import { defineComponent, onMounted, onBeforeMount, ref } from "vue";
import OptionsWidget from "@/components/widgets/tables/OptionsWidget.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
const toast = useToast();

import { Actions } from "@/store/enums/StoreEnums";
export default defineComponent({
  name: "dashboard",
  components: {
    OptionsWidget,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const Options = ref(null);
    onBeforeMount(async () => {
      await store.dispatch(Actions.OPTIONS).then((response) => {
        if (response.status == 200) {
          Options.value = response.data.options;
        } else {
          toast.error(
            `Opsiyon güncellenirken getir tarafından hata alındı lütfen bu ürünü Getir Paneli üzerinden güncellemeyi deneyin. `
          );
        }
      });
    });

    onMounted(() => {
      setCurrentPageTitle("Opsiyon Açma, Kapatma");
    });
    return {
      Options,
    };
  },
});
