
import { defineComponent, ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { getCookie, removeCookie } from "typescript-cookie";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { IIntegrationElement } from "@/dtos/IIntegrationElement";
import { IntegrationOption } from "@/dtos/IntegrationOption";
import { ProductStatus } from "@/dtos/ProductStatus";
import { IntegrationProduct } from "@/dtos/IntegrationProduct";
const toast = useToast();

export default defineComponent({
  name: "kt-widget-4",
  components: {},
  props: {
    Options: Array,
  },

  setup(props) {
    const store = useStore();
    const List = ref();
    const branch = ref();
    const mergedList = ref();
    const filterparams = ref("");
    const filterStatus = ref();
    const router = useRouter();
    const filtervaluem = computed(() => {
    let tempdata = List.value !== undefined ? List.value : props.Options;
    
      return tempdata.filter((item: any) => {
      // item.integrationOptions array'i içerisindeki her bir integration için filtreleme yapılacak
      const integrationFiltered = item.integrationOptions.some((integration: any) => {
        const filterByName = filterparams.value === "" || integration.option.Name.toLowerCase().includes(filterparams.value.toLowerCase());
        const filterByStatus = filterStatus.value == null || integration.option.Status === filterStatus.value;

        // Her iki koşulun da sağlanması gerekiyor
        return filterByName && filterByStatus;
      });

      return integrationFiltered;
    });
  });
    const changeStatusForIOption = async (
      item: IntegrationOption,
      status: ProductStatus
    ) => {
      const data = {
        Options: item,
        status: status,
      };
      store
        .dispatch(Actions.CHANGEOPTIONSSTATUS, data)
        .then((r) => {
          if (r != 201) {
            toast.error(
              `Opsiyon güncellenirken getir tarafından hata alındı lütfen bu ürünü Getir Paneli üzerinden güncellemeyi deneyin. `
            );
          } else {
            if (item.option) {
              item.option.Status = status;
            }
            toast.success(
              `${item.option?.Name
              } ${IntegrationProduct.friendlyName(
                item
              )} üzerinde satışa ${status == 1 ? "açıldı" : "kapatıldı"
              }`
            );
          }
        })
        .catch((error) => {
          toast.error(
            `Opsiyon güncellenirken getir tarafından hata alındı lütfen bu ürünü Getir Paneli üzerinden güncellemeyi deneyin. `
          );
        });
      console.log(data);
    };
    return {
      changeStatusForIOption,
      List,
      mergedList,
      branch,
      reinitializeComponents,
      filtervaluem,
      filterparams,
      filterStatus,
      IntegrationOption,
      IIntegrationElement,
      ProductStatus,
    };
  },
});
