import { IIntegrationElement } from "./IIntegrationElement";
import { ProductStatus } from "./ProductStatus";

export class IntegrationOption extends IIntegrationElement {
  option: Options | undefined;
}

export interface Options {
  Id: string;
  Status: ProductStatus;
  Name: string;
  ProductId: string;
  ProductName: string;
  Tag: string;
}
